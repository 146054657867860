import { useState } from 'react';

import { AutoCompleteInput } from 'components/Common/AutoCompleteOption';

import { OutputTag, OutputType } from 'types';
import { outputTypeOptions } from 'utils/eventHelpers';

interface OutputTypeBySocialProps {
  type: any;
  onChange: (
    type: OutputType,
    updatedSourceDetailsKey: string,
    updatedSourceDetailsValue: string | number,
    spec?: any
  ) => void;
  details: any;
  spec?: any;
}
const OutputTypeBySocial = ({ type, onChange, details, spec }: OutputTypeBySocialProps) => {
  const [socialOutputType, setSocialOutputType] = useState<any>(null);
  const onInputChange = (value) => {
    if (!value) {
      setSocialOutputType(null);
      onChange(type, 'tag', '', spec);
      return;
    }
    setSocialOutputType(value);
    onChange(type, 'tag', value.value, spec);
  };
  return (
    <AutoCompleteInput
      label={'Destination'}
      options={
        type === OutputType.HLS
          ? outputTypeOptions.filter((options) => options.label === OutputTag.NHL)
          : outputTypeOptions.filter((options) => options.label !== OutputTag.NHL)
      }
      disabled={Boolean(type === OutputType.HLS)}
      value={details?.tag ? { label: details.tag, value: details.tag } : socialOutputType}
      onChange={onInputChange}
      customStyle
    />
  );
};

export default OutputTypeBySocial;
