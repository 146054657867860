import { useMemo } from 'react';
import ReactFlow, { Background, Controls, useEdgesState, useNodesState } from 'reactflow';

import { Box } from '@mui/system';

import { ControlFeedNode } from 'components/ControlCenter/FeedNodes/ControlFeedNode';
import { OutputFeedNode } from 'components/ControlCenter/FeedNodes/OutputFeedNode';
import { SourceFeedNode } from 'components/ControlCenter/FeedNodes/SourceFeedNode';
import { SwitchFeedNode } from 'components/ControlCenter/FeedNodes/SwitchFeedNode';

import { EventStreamStatus } from 'constants/StreamConstants';

import { useStreamControl } from 'hooks/useStreamControl';

import { CustomFeedGraphNodeType, SingleAPIEvent } from 'types';
import {
  AnimatedEdgeStyles,
  calculateGraphNodeHorizontalSeperation,
  calculateLayoutForGraph,
  DefaultEdgeStyles,
  transformEventIntoGraph,
} from 'utils/eventHelpers';

import 'reactflow/dist/style.css';

interface IFeedGraphProps {
  event: SingleAPIEvent;
}

export const FeedGraph = (props: IFeedGraphProps) => {
  const { switchSource } = useStreamControl(props.event.id);
  const [graphNodes, setGraphNodes, onGraphNodesChange] = useNodesState([]);
  const [graphEdges, setGraphEdges, onGraphEdgesChange] = useEdgesState([]);

  const nodeTypes = useMemo(
    () => ({
      [CustomFeedGraphNodeType.SOURCE_NODE]: SourceFeedNode,
      [CustomFeedGraphNodeType.SWITCH_NODE]: SwitchFeedNode,
      [CustomFeedGraphNodeType.CONTROL_NODE]: ControlFeedNode,
      [CustomFeedGraphNodeType.OUTPUT_NODE]: OutputFeedNode,
    }),
    []
  );

  const handleSwitchToggle = (id: string, switchState: boolean) => {
    setGraphNodes((nodes) => {
      return nodes.map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            isSwitchedOn: switchState,
          };
        } else {
          node.data = {
            ...node.data,
            isSwitchedOn: false,
          };
        }
        return node;
      });
    });
    setGraphEdges((edges) => {
      return edges.map((edge) => {
        if (switchState && (edge.source === id || edge.target === id)) {
          edge.animated = false;
          edge.style = { ...AnimatedEdgeStyles.style };
          edge.markerEnd = { ...AnimatedEdgeStyles.markerEnd };
          return edge;
        }
        edge.animated = false;
        edge.style = { ...DefaultEdgeStyles.style };
        edge.markerEnd = { ...DefaultEdgeStyles.markerEnd };

        return edge;
      });
    });
    const sourceId = id.replace(`-${CustomFeedGraphNodeType.SWITCH_NODE}`, '');
    switchSource(sourceId);
  };

  const handleControlClick = (state: EventStreamStatus, sourceId: string) => {
    setGraphEdges((edges) => {
      return edges.map((edge) => {
        if (sourceId && edge.source === CustomFeedGraphNodeType.CONTROL_NODE) {
          edge.animated = true;
          edge.style = { ...AnimatedEdgeStyles.style };
          edge.markerEnd = { ...AnimatedEdgeStyles.markerEnd };
          return edge;
        }
        if (
          edge.source === sourceId ||
          edge.source === `${sourceId}-${CustomFeedGraphNodeType.SWITCH_NODE}`
        ) {
          edge.animated = true;
          edge.style = { ...AnimatedEdgeStyles.style };
          edge.markerEnd = { ...AnimatedEdgeStyles.markerEnd };
          return edge;
        }

        return edge;
      });
    });
  };

  useMemo(() => {
    const feedGraph = transformEventIntoGraph({
      event: props.event,
      onSwitchToggle: handleSwitchToggle,
      onControlClick: handleControlClick,
    });
    const horizontalSep = calculateGraphNodeHorizontalSeperation(props.event);
    const { nodes: layoutedNodes, edges: layoutedEdges } = calculateLayoutForGraph({
      nodes: feedGraph.nodes,
      edges: feedGraph.edges,
      horizontalSep,
    });
    setGraphNodes(layoutedNodes);
    setGraphEdges(layoutedEdges);
  }, [props.event]);

  return (
    <Box height={`calc(90vh - 60px - 60px - 70px)`}>
      <ReactFlow
        nodes={graphNodes}
        edges={graphEdges}
        zoomOnDoubleClick={false}
        onNodesChange={onGraphNodesChange}
        onEdgesChange={onGraphEdgesChange}
        nodeTypes={nodeTypes}
        // onNodeClick={handleNodeClick}
        proOptions={{ hideAttribution: true }}
        snapToGrid
        fitView
        minZoom={0.1}
        fitViewOptions={{ padding: 0.1 }}
        id={props.event.id}
        // defaultViewport={{ x: 120.12, y: 170.91, zoom: 1.1 }}
      >
        <Background />
        <Controls showInteractive={false} style={{ zIndex: 12 }} />
      </ReactFlow>
    </Box>
  );
};
