import { useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Handle, NodeProps, Position } from 'reactflow';
import { useQuery } from '@tanstack/react-query';
import theme from 'themes/baseTheme';

import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { ContentCopy, LibraryAddCheck } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { Center } from 'components/Common/Centered';
import { DeviceStatus } from 'components/Common/DeviceStatus';
import { PopUp } from 'components/PopUp';
import { ResourceDetails } from 'components/Resource/ResourceDetails';

import * as ResourceService from 'services/ResourceService';

import { OutputTypeDisplayName } from 'constants/CommonConstants';
import { UserTourClassSelector } from 'constants/UserTourConstants';

import environment from 'config';
import { FeedGraphOutputNodeData, OutputType, PopUpButtonProps } from 'types';
import { GRAPH_NODE_DIMENSIONS } from 'utils/eventHelpers';
import { getStatusColor } from 'utils/helpers';

import { HLSOutputNode } from './HLSOutputNode';

const NodePreview = (props: { output: FeedGraphOutputNodeData }) => {
  const output = props.output;
  const outputUrl = `https://hls.${environment.baseUrl}/${output.group}/20232024/${output.eventId}/live.m3u8`;
  switch (output.type) {
    case OutputType.HLS:
      return <HLSOutputNode outputUrl={outputUrl} isActive={true} />;

    case OutputType.RTMP:
    case OutputType.SRT:
      return (
        <>
          <Center width="100%" height="100%" bgcolor="grey.50" borderRadius={1}>
            <Typography variant="h4" color="grey.600">
              <b>{OutputTypeDisplayName[props.output.type]}</b>
            </Typography>
          </Center>
        </>
      );
    default:
      <></>;
  }
};

const NodeDetails = (props: { output: FeedGraphOutputNodeData }) => {
  const renderDetails = () => {
    switch (props.output.type) {
      case OutputType.HLS:
      case OutputType.RTMP:
      case OutputType.SRT:
        return (
          <DeviceStatus
            status={
              props.output.status ? props.output.status : { type: 'NEGATIVE', value: 'NOT PRESENT' }
            }
          />
        );
      default:
        return <></>;
    }
  };
  const statusColor = getStatusColor(props.output.status?.type);
  const primaryColor = theme.palette.primary.main;
  return (
    <>
      {props.output.tag && (
        <Box
          position="absolute"
          top="-24px"
          left="0px"
          bgcolor={primaryColor + '20'}
          px={1}
          py={0.1}
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
          }}
          zIndex={-1}
        >
          <Typography color={'primary.main'}>
            <b>{props.output.tag}</b>
          </Typography>
        </Box>
      )}

      <Box
        className="statusBox"
        position="absolute"
        top="-22px"
        right="2px"
        zIndex={1}
        bgcolor={statusColor + '20'}
        px={1}
        py={0.1}
        sx={{
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '4px',
        }}
      >
        {renderDetails()}
      </Box>
    </>
  );
};

export const OutputFeedNode = ({
  data,
  id,
  type,
}: NodeProps<FeedGraphOutputNodeData>): JSX.Element => {
  const [viewPopUpOpen, setViewPopUpOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const viewButtonAttr: PopUpButtonProps[] = [
    {
      name: 'OK',
      handler: () => {
        setViewPopUpOpen(false);
      },
      variant: 'contained',
    },
  ];

  const {
    refetch: refetchResources,
    isFetching: isResourcesFetching,
    data: resourceDetails = [],
  } = useQuery(['Resource', id], () => ResourceService.getOutputResourcesByID(id), {
    enabled: true,
  });

  const fetchURL = useMemo(() => {
    switch (data.type) {
      case OutputType.HLS:
        return `https://hls.${environment.baseUrl}/${data.group}/20232024/${data.eventId}/live.m3u8`;
      case OutputType.RTMP:
        return data.specifications.url;
      case OutputType.SRT:
        return `${data.specifications.host}:${data.specifications.port}`;
      default:
        return null;
    }
  }, [resourceDetails]);

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        style={{ visibility: 'hidden' }}
      />
      <Box
        style={{
          width: GRAPH_NODE_DIMENSIONS[type].width,
          height: GRAPH_NODE_DIMENSIONS[type].height,
        }}
        className={UserTourClassSelector.SHOW_OUTPUTS}
        p="2px"
        border="1px solid"
        bgcolor="white"
        borderColor="grey.100"
        borderRadius={2}
        boxShadow={1}
        overflow="hidden"
        onClick={() => {
          setViewPopUpOpen(true);
          refetchResources();
        }}
      >
        <NodePreview output={data} />
        <NodeDetails output={data} />
      </Box>
      {fetchURL !== null ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'grey.100',
            padding: '8px',
            width: '100%',
            position: 'absolute',
            bottom: '-55px',
            borderRadius: '0px 0px 5px 5px',
          }}
        >
          <Typography
            sx={{
              width: '80%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <b>{fetchURL}</b>
          </Typography>
          <CopyToClipboard text={fetchURL} onCopy={() => setCopied(true)}>
            <IconButton>
              {copied ? <LibraryAddCheck color="success" /> : <ContentCopy />}
            </IconButton>
          </CopyToClipboard>
        </Box>
      ) : null}

      <PopUp
        isPopUpOpen={viewPopUpOpen}
        buttons={viewButtonAttr}
        close={viewButtonAttr[0].handler}
        title="Resource Details"
        fullWidth={true}
        maxWidth="md"
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ cursor: 'pointer' }}
          onClick={() => refetchResources()}
        >
          <RefreshIcon color="primary" />
        </Stack>
        {isResourcesFetching ? (
          <Center minHeight="200px">
            <CircularProgress />
          </Center>
        ) : resourceDetails.length !== 0 ? (
          resourceDetails.map((resource) => (
            <ResourceDetails key={resource.id} resource={resource} />
          ))
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <Typography variant="h6" sx={{ color: 'grey.600' }}>
              No Resources found!
            </Typography>
          </Box>
        )}
      </PopUp>
    </>
  );
};
