import { Amplify, Auth, Hub } from 'aws-amplify';
import { Buffer } from 'buffer';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';

import { Theme } from '@mui/material';
import { red } from '@mui/material/colors';
import { SystemStyleObject } from '@mui/system';

import { ErrorStates, UserRole } from 'constants/AuthConstants';
import { DATE_FORMAT } from 'constants/CommonConstants';
import { FeedNodeStatusType } from 'constants/DeviceStates';

import environment from 'config';
import { APIEvent, User } from 'types';

// dummy "makeStyles()" implementation that was in material-ui v4, that works with v5's SxProps
type CustomStyleObject = SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
export function buildStyles<T extends string>(
  styles: Record<T, CustomStyleObject>
): Record<T, CustomStyleObject> {
  return styles as Record<T, CustomStyleObject>;
}

// configures amplify globally, call this only once on app init
export const configureAmplify = (): void => {
  const {
    REGION,
    USER_POOL_ID,
    APP_CLIENT_ID,
    APP_DOMAIN,
    CALLBACK_URL,
    SIGNOUT_URL,
    RESPONSE_TYPE,
    SCOPE,
  } = environment.cognito;
  const { REST } = environment.API;
  Amplify.configure({
    Auth: {
      region: REGION,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: APP_CLIENT_ID,
      mandatorySignIn: false,
      oauth: {
        domain: APP_DOMAIN,
        scope: SCOPE,
        redirectSignIn: CALLBACK_URL,
        redirectSignOut: SIGNOUT_URL,
        responseType: RESPONSE_TYPE,
      },
    },
    API: {
      endpoints: [
        {
          name: REST.NAME,
          endpoint: REST.ENDPOINT,
          region: REST.REGION,
          custom_header: async () => ({
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          }),
        },
      ],
    },
  });

  Hub.listen('auth', (data) => {
    // listen to any kind of unknown auth error failures and inform users by toast on login page
    // TODO: Move this to a top global AuthError content,
    // so that it can fire a SnackBar when error occurs wherever in the app,
    // instead of using sessionStorage
    if (data?.payload?.event === 'signIn_failure') {
      sessionStorage.setItem('AUTH_ERROR', ErrorStates.SIGN_IN_FAILURE);
    }
  });

  localStorage.setItem('REGION', environment.regionCode);
};

// parses timestamp to format(EST) - 05:23:34 PM EST, 19 Aug 2022
export const parseTimeStampToEST = (timestamp: number): string => {
  const estTime = new Date(
    new Date(timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' })
  );

  return `${format(estTime, 'hh:mm:ss aa')} EST, ${format(estTime, 'dd MMM YYY')}`;
};

export const validateUser = (userRole: UserRole, roles: UserRole[]): boolean => {
  return roles.findIndex((role) => userRole === role) !== -1;
};

export const isUserInputValid = (userToCheck: User, currentRole: UserRole): boolean => {
  if (currentRole !== UserRole.GROUP_MANAGER) {
    return Boolean(
      userToCheck?.email?.trim() &&
        userToCheck?.firstName?.trim() &&
        userToCheck?.lastName?.trim() &&
        userToCheck?.group?.trim()
    );
  }
  return Boolean(
    userToCheck?.email?.trim() && userToCheck?.firstName?.trim() && userToCheck?.lastName?.trim()
  );
};

export const formatUTCTimeToDate = (date: string | Date): string => {
  return format(new Date(date), DATE_FORMAT);
};

export const formatName = (firstName: string, lastName: string): string => {
  let name = firstName;
  if (lastName) name += ` ${lastName}`;
  return name;
};

// parses date object to format(EST) - 05:23 PM EST
export const parseDateToESTTime = (date: string): string => {
  const estTime = new Date(
    new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York' })
  );

  return `${format(estTime, 'hh:mm a')} EST`;
};

export function imageFromArrayBuffer(dataBuffer: Array<number>, format = 'image/jpeg'): string {
  const b64 = Buffer.from(dataBuffer).toString('base64');
  return `data:${format};base64,${b64}`;
}

/**
 * @param date
 * @returns the starting time 00:00:00 of the day as timestamp
 */
export const getRoundedTsFromDate = (date: Date): number => {
  date.setUTCHours(0, 0, 0, 0);
  return date.getTime();
};

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// parses timestamp to format(GMT) - 05:23:34 PM UTC, 19 Aug 2022
export const parseTimeStampToUTC = (timestamp: number): string => {
  const date = new Date(new Date(timestamp).toLocaleString('en-US', { timeZone: 'UTC' }));
  return `${format(date, 'hh:mm:ss aa')} UTC, ${format(date, 'dd MMM YYY')}`;
};

// parses date object to format(GMT) - 05:23 PM UTC
export const parseDateToUTCTime = (date: string): string => {
  const utcTime = new Date(new Date(date).toLocaleString('en-US', { timeZone: 'UTC' }));
  return `${format(utcTime, 'hh:mm a')} UTC`;
};
export const uuid = nanoid;

export const getNumberofUniqueGroups = (events: APIEvent[]) =>
  events.reduce((resultSet, item) => resultSet.add(item.group), new Set()).size;

export const timeZoneAbbreviated = () => {
  const tz = new Date().toString().match(/\((.+)\)/);
  if (tz[1]?.includes(' ')) {
    return tz[1]
      .split(' ')
      .map((first) => first[0])
      .join('');
  } else {
    return tz;
  }
};

export const getESTFromUTC = (timeStampUTC) => {
  return new Date(timeStampUTC).toLocaleString('en-US', {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });
};

export const getStatusColor = (status: FeedNodeStatusType | string) => {
  return status === FeedNodeStatusType.POSITIVE
    ? '#24C142'
    : status === FeedNodeStatusType.NEGATIVE
    ? red[600]
    : '#0A0A0A19';
};
