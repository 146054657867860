import { memo } from 'react';
import _ from 'lodash';

import {
  Autocomplete,
  Box,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { RefreshRounded } from '@mui/icons-material';

import { SourceTypeDisplayName } from 'constants/CommonConstants';

import { AWSResource, OutputType, Slate, SourceType } from 'types';

import OutputTypeBySocial from './OutputTypeBySocial';

export const EventInput = memo(function SourceInput<T extends SourceType | OutputType>({
  spec,
  type,
  inputType,
  details,
  onChange,
  options,
  selectedSources,
  onClickRefreshSlates,
  isFetchingSlates,
  group,
}: {
  spec: any;
  type: T;
  inputType: string;
  details: Record<string, string | number>;
  onChange: (
    type: SourceType | OutputType,
    updatedSourceDetailsKey: string,
    updatedSourceDetailsValue: string | number,
    spec?: any
  ) => void;
  options?: {
    awsResources: AWSResource<any>[];
    slates: Slate[];
  };
  selectedSources?: any;
  onClickRefreshSlates?: () => void;
  isFetchingSlates?: boolean;
  group?: string;
}): JSX.Element {
  const removeSelectedOption = (sourceType, resources) => {
    const filteredResources = resources.filter((resource) => {
      const selectedSourceKey = sourceType === SourceType.ELEMENTAL_LINK ? 'deviceId' : 'slateId';
      return !selectedSources[sourceType].some(
        (selectedSource) => selectedSource[selectedSourceKey] === resource.id
      );
    });

    return _.sortBy(filteredResources, [
      (o) => (o.group === group ? 0 : 1),
      'group',
      (o) => (sourceType === SourceType.ELEMENTAL_LINK ? o.details?.Name : o?.name),
    ]);
  };

  switch (type) {
    case SourceType.ELEMENTAL_LINK:
      const currDevice = options.awsResources.find((resource) => resource.id === spec.deviceId);
      return (
        <>
          <Autocomplete
            value={currDevice}
            size="small"
            groupBy={(o) => o.group}
            options={removeSelectedOption(type, options.awsResources)}
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                width: '100%',
              },
              '& .MuiAutocomplete-endAdornment': {
                'button:first-child': {
                  display: 'none',
                },
              },
            }}
            onChange={(_, value: AWSResource<any>) => {
              onChange(type, 'deviceId', value.id, spec);
            }}
            getOptionLabel={(value: AWSResource<any>) => value?.details?.Name || value.id || ''}
            renderInput={(params) => <TextField {...params} label="Select Device" />}
          />
        </>
      );
    case SourceType.SLATE_INPUT:
      const currSlate = options.slates.find((resource) => resource.id === spec.slateId);

      return (
        <>
          <Stack direction="row" width="100%" spacing={1}>
            <Autocomplete
              value={currSlate}
              size="small"
              groupBy={(o) => o.group}
              options={removeSelectedOption(type, options.slates)}
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  width: '100%',
                },
                '& .MuiAutocomplete-endAdornment': {
                  'button:first-child': {
                    display: 'none',
                  },
                },
              }}
              onChange={(_, value: Slate) => onChange(type, 'slateId', value.id, spec)}
              getOptionLabel={(value: Slate) => value.name || ''}
              renderInput={(params) => <TextField {...params} label="Select Slate" />}
            />
            <Tooltip title="refresh">
              <IconButton size="small" onClick={onClickRefreshSlates} disabled={isFetchingSlates}>
                <RefreshRounded />
              </IconButton>
            </Tooltip>
          </Stack>

          <Typography variant="subtitle2" sx={{ color: 'grey.600', my: 1 }}>
            or
          </Typography>
          <Link href="/slates" target="_blank" sx={{ fontSize: 'medium' }}>
            Create New Slate
          </Link>
        </>
      );
    case SourceType.ZIXI:
      return (
        <Typography variant="h6" sx={{ color: 'grey.600' }}>
          <b>{SourceType.ZIXI}</b>
        </Typography>
      );
    case SourceType.SRT || OutputType.SRT:
      if (inputType === 'SOURCE') {
        return (
          <Typography variant="h6" sx={{ color: 'grey.600' }}>
            <b>{SourceTypeDisplayName[SourceType.SRT]}</b>
          </Typography>
        );
      } else {
        return (
          <>
            <TextField
              label="Host"
              fullWidth
              size="small"
              sx={{ mb: 1.5 }}
              value={details?.['host']}
              onChange={(e) => onChange(type, 'host', e.target.value, spec)}
            />
            <Stack direction={'row'} spacing={1}>
              <TextField
                label="Inbound port"
                fullWidth
                size="small"
                type="number"
                value={details?.['port']}
                onChange={(e) => onChange(type, 'port', e.target.value, spec)}
              />
              <OutputTypeBySocial type={type} onChange={onChange} details={details} spec={spec} />
            </Stack>
          </>
        );
      }
    case SourceType.RTMP || OutputType.RTMP:
      if (inputType === 'SOURCE') {
        return (
          <Typography variant="h6" sx={{ color: 'grey.600' }}>
            <b>{SourceType.RTMP}</b>
          </Typography>
        );
      } else {
        const url = details?.['url'] as string;
        return (
          <Box>
            <TextField
              label={
                url !== '' && !url?.startsWith('rtmp://') && !url?.startsWith('rtmps://')
                  ? 'starts with ( rtmp:// or rtmps:// )'
                  : !url?.split('://')[1] && url !== ''
                  ? 'Invalid URL'
                  : 'RTMP Source URL'
              }
              fullWidth
              size="small"
              value={url}
              error={
                url !== '' && !url?.startsWith('rtmp://') && !url?.startsWith('rtmps://')
                  ? true
                  : !url?.split('://')[1] && url !== ''
                  ? true
                  : false
              }
              onChange={(e) => onChange(type, 'url', e.target.value, spec)}
            />
            <Stack direction={'row'} spacing={1} alignItems="center">
              <TextField
                label="Stream ID"
                fullWidth
                size="small"
                sx={{ marginTop: '10px' }}
                value={details?.['streamId']}
                onChange={(e) => onChange(type, 'streamId', e.target.value, spec)}
              />
              <Box alignSelf="end">
                <OutputTypeBySocial type={type} onChange={onChange} details={details} spec={spec} />
              </Box>
            </Stack>
          </Box>
        );
      }
    case OutputType.HLS:
      return (
        <Box sx={{ position: 'relative', height: '80px' }}>
          <Typography variant="h6" sx={{ color: 'grey.600' }}>
            <b>{OutputType.HLS}</b>
          </Typography>
          {inputType === 'OUTPUT' && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '-15px',
                right: '0px',
              }}
            >
              <OutputTypeBySocial type={type} onChange={onChange} details={details} />
            </Box>
          )}
        </Box>
      );
    default:
      return <></>;
  }
});
